<template>
  <v-theme-provider dark>
    <base-section
      id="team"
      class="blue-grey lighten-4"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <router-link to="/team">&lt;&lt; Back</router-link>
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title="Team"
              class="brown lighten-1"
            />
            <base-body
              space="0"
              class="blue-grey darken-3"
            >
              <img
                :src="'/images/employees/' + teamData.imagename"
                alt="employee"
              /><br />
              Name : {{ teamData.name }} <br />
              Title : <span v-html="teamData.title" /> <br />
              NMLS : {{ teamData.nmls }} <br />
              Phone : {{ teamData.phone }} <br />
              Email : {{ teamData.email }} <br />
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { FirebaseTeam } from '@/database/'

  export default {
    name: 'Team',
    components: {
    },
    data: () => ({
      teamData: {},
      thisID: '',
    }),
    computed: {
    },
    mounted () {
      this.thisID = this.$route.params.memberid
      this.initialize()
    },
    methods: {
      initialize () {
        this.getTeam()
      },
      getTeam () {
        this.teamData = []
        FirebaseTeam.doc(this.thisID)
          .get()
          .then((doc) => {
            this.teamData = doc.data()
            console.log(this.teamData)
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      },
    },
  }
</script>
